import React, { useState, useEffect } from "react";
import Router from "next/router";
import fetch from "../utils/fetch";
import { encrypt } from "../utils/cipher";
import { getTracker, DataTracker } from "../components/Tracker";
import {
  isEmpty,
  isCharExactLengthValid,
  isEmailFormat,
  isCharLengthValid,
  IsSelectInputSelected,
} from "../utils/validator";
import { validatePhoneNumber } from "../utils/form/FormInputValidate";
import kota from "../data/DataKotaLeads";
import { scrollTo } from "../utils/scroll-to";
import Description from "../components/UserInterface/Description";
import { Input } from "./Form/Input";
import { Select } from "./Form/Select";
import { CheckBox } from "./Form/Checkbox";
import { GACustomEvent } from "src/utils/analytics";
import { htmlEncode } from "src/utils/helpers";

const LeadsForm = ({
  title,
  description,
  leadFormType = "LEADFORM",
  valueDump,
}) => {
  const [nameLead, setNameLead] = useState("");
  const [phoneLead, setPhoneLead] = useState("");
  const [cityLead, setCityLead] = useState("Pilih Kota");
  const [areaLead, setAreaLead] = useState("Pilih Area");
  const [emailLead, setEmailLead] = useState("");
  const [checkedLead, setCheckedLead] = useState(false);
  const [errorName, setErrorName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorArea, setErrorArea] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCheck, setErrorCheck] = useState("");
  const [errorCapt, setErrorCapt] = useState("");
  const [formValidated, setFormValidated] = useState(true);
  const [btnSubmit, setBtnSubmit] = useState("Ajukan Sekarang");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const prov = kota.map((i) => i.provinsi);

  useEffect(() => {
    if (!formValidated) {
      scrollToError();
      setFormValidated(true);
    }
  }, [formValidated]);

  useEffect(() => {
    checkedLead === true ? setIsBtnDisabled(false) : setIsBtnDisabled(true);
  }, [checkedLead]);

  const handleName = (e) => {
    const re = /^[A-Za-z ]+$/;
    const name = htmlEncode(e.target.value);
    if (name === "" || re.test(name)) {
      setNameLead(name);
      setErrorName("");
    }
  };

  const handleLeadsFormSubmit = async (e) => {
    if (isSubmitting || isBtnDisabled) {
      return;
    }

    if (!validateForm()) {
      scrollToError();

      return false;
    }

    GACustomEvent("store_purchase_detail_find_out_more_popup", "click");
    GACustomEvent("store_purchase_detail_submit_form", "click");
    await submitLeadForm();
    await resetForm();
  };

  const submitLeadForm = async (e) => {
    setBtnSubmit("Mengirim ...");
    setIsSubmitting(true);
    const postLeadsObject = {
      id_source: leadFormType,
      name: nameLead,
      phone: phoneLead,
      email: emailLead,
      city: cityLead,
      area: areaLead,
      value_dump: valueDump,
      ...DataTracker(
        getTracker().utm_source,
        getTracker().utm_campaign,
        getTracker().utm_medium,
        getTracker().utm_content,
        getTracker().user_agent,
        getTracker().gclid,
        getTracker().guid
      ),
    };

    const { status } = await fetch("POST", `/form/lead-form`, {
      data: encrypt(postLeadsObject),
    });

    await setIsSubmitting(false);
    if (status === 200) {
      await Router.push({
        pathname: "/thank-you",
        query: { form: "lead-form" },
      });
      resetForm();
    } else {
      setBtnSubmit("Gagal Mengirim");
      setTimeout(() => {
        setBtnSubmit("Coba Lagi");
      }, 3000);
    }
  };

  const resetForm = (e) => {
    setNameLead("");
    setPhoneLead("");
    setCityLead("");
    setAreaLead("");
    setEmailLead("");
    setCheckedLead(false);
    setErrorName("");
    setErrorPhone("");
    setErrorCity("");
    setErrorArea("");
    setErrorEmail("");
    setErrorCheck("");
    setErrorCapt("");
  };

  const scrollToError = () => {
    const elem = document.getElementsByClassName("input--error")[0];
    if (!elem) {
      return;
    }
    const topPos = elem.offsetParent.offsetTop;

    const scrollingElement =
      document.body.style.overflow === "hidden" ||
      document.getElementsByTagName("html")[0].style.overflow === "hidden"
        ? document.getElementsByClassName("form-wrapper")[0].parentElement
        : document.scrollingElement;

    scrollTo(scrollingElement, topPos - 300, 600);
  };

  const validateForm = (e) => {
    if (
      !validateName(nameLead, setErrorName) ||
      !validatePhoneNumber(true, phoneLead, setErrorPhone, setPhoneLead) ||
      !validateCity(cityLead, setErrorCity) ||
      !validateArea(areaLead, setErrorArea) ||
      !validateEmail(emailLead, setErrorEmail) ||
      !validateCheckbox(checkedLead, setErrorCheck)
    ) {
      setFormValidated(false);
      return false;
    }
    return true;
  };

  const validateName = (val, errorSetter) => {
    errorSetter("");
    if (isEmpty(val)) {
      errorSetter("Nama Lengkap Wajib Diisi");
      return false;
    } else if (!isCharLengthValid(val)) {
      errorSetter("Nama Lengkap Harus Terdiri Dari 3 - 25 Karakter");
      return false;
    }
    return true;
  };

  const handlePhone = (e) => {
    const re = /^[0-9]{1,13}$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setErrorPhone("");
      setPhoneLead(e.target.value);
    }
  };

  const handleCity = (e) => {
    setCityLead(e.target.value);
  };

  const validateCity = (val, errorSetter) => {
    errorSetter("");
    if (!IsSelectInputSelected(val)) {
      errorSetter("Kota Wajib Dipilih");
      return false;
    }
    return true;
  };

  const handleArea = (e) => {
    setAreaLead(e.target.value);
  };
  const validateArea = (val, errorSetter) => {
    errorSetter("");
    if (!IsSelectInputSelected(val)) {
      errorSetter("Area Wajib Dipilih");
      return false;
    }
    return true;
  };

  const handleEmail = (e) => {
    setEmailLead(e.target.value);
    setErrorEmail("");
  };

  const validateEmail = (val, errorSetter) => {
    errorSetter("");

    if (isEmpty(val)) {
      errorSetter("Email Wajib Diisi");
      return false;
    } else if (!isEmailFormat(val)) {
      errorSetter("Format Email Tidak Sesuai");
      return false;
    }
    return true;
  };

  const handleCheck = (e) => {
    setCheckedLead(e.target.checked);
  };

  const validateCheckbox = (val, errorSetter) => {
    errorSetter("");

    if (!val) {
      errorSetter("Mohon isi bagian ini");
      return false;
    }
    return true;
  };

  return (
    <div className="form-wrapper">
      <div className="form-title">{title}</div>
      <Description
        fontSize="16px"
        font="Roboto"
        text={description}
        align="center"
        maxWidth="670px"
        width="100%"
      />
      <Input
        onChange={(e) => handleName(e)}
        value={nameLead}
        type="text"
        pattern="[A-Za-z]"
        title="Nama Lengkap"
        errMsg={errorName}
        onBlur={(e) => validateName(e.target.value, setErrorName)}
      ></Input>
      <Input
        onChange={(e) => handlePhone(e)}
        value={phoneLead}
        type="tel"
        pattern="^\d{2}-\d{3}$"
        title="Nomor Handphone"
        errMsg={errorPhone}
        placeholder="Harus diawali dengan 08 atau 628"
        onBlur={(e) =>
          validatePhoneNumber(true, e.target.value, setErrorPhone, setPhoneLead)
        }
      ></Input>
      <Select
        onChange={(e) => handleCity(e)}
        value={cityLead}
        title="Pilih Kota"
        data={prov}
        errMsg={errorCity}
        onBlur={(e) => validateCity(e.target.value, setErrorCity)}
      ></Select>
      {kota.map((j, key) => {
        if (cityLead === j.provinsi) {
          return (
            <Select
              key={key}
              onChange={handleArea}
              value={areaLead}
              title="Pilih Area"
              errMsg={errorArea}
              data={j.kabupaten}
              onBlur={(e) => validateArea(e.target.value, setErrorArea)}
            ></Select>
          );
        }
      })}
      <Input
        onChange={handleEmail}
        value={emailLead}
        type="email"
        title="Alamat Email"
        errMsg={errorEmail}
        onBlur={(e) => validateEmail(e.target.value, setErrorEmail)}
      ></Input>
      <CheckBox
        onChange={handleCheck}
        title={`Saya dengan ini menyatakan setuju dan memberikan kewenangan Home
              Credit untuk menggunakan informasi pribadi saya untuk tujuan yang
              berkaitan dengan penawaran dan/atau pemasaran dan/atau promosi
              produk Home Credit.`}
        checked={checkedLead}
        errMsg={errorCheck}
        margin="0 0 16px"
      ></CheckBox>
      <div className="form-button">
        <button
          className={`button-submit ${
            isBtnDisabled ? " button-submit--disabled" : ""
          }`}
          onClick={handleLeadsFormSubmit}
          disabled={isBtnDisabled}
        >
          {btnSubmit}
        </button>
      </div>
      <style jsx>{`
        .button-red-disabled {
          background: #4f4f4f;
          color: #fff;
          cursor: not-allowed !important;
        }
        .form-button {
          margin: 20px 0 50px 0;
        }
        .form-title {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }
        .button-submit {
          height: 40px;
          width: 160px;
          background-color: #e11931;
          color: #ffffff;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 0.26px;
          line-height: 20px;
          justify-content: center;
          align-items: center;
          display: flex;
          margin: 20px 0 0 0;
          border-radius: 8px;
          cursor: pointer;
        }
        .button-submit--disabled {
          background-color: #4f4f4f;
        }
        @media only screen and (max-width: 600px) {
          .button-submit {
            height: 35px;
            width: 130px;
            font-size: 12px;
            margin: auto;
            display: block;
          }
        }
      `}</style>
    </div>
  );
};

export default LeadsForm;
